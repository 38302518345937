export const ModernMTLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="193"
      viewBox="0 0 193 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...props}
    >
      <title>ModernMT Logo</title>
      <path
        d="M20.0035 2.82646C29.5096 2.82646 37.1806 10.5327 37.1806 20.0035C37.1806 29.4743 29.4743 37.1736 20.0035 37.1736C10.5327 37.1736 2.83349 29.4743 2.83349 20.0035C2.86167 10.4975 10.5679 2.82646 20.0035 2.82646ZM20.0035 0C8.95835 0 0 8.95749 0 20.0035C0 31.0496 8.96451 40 20.0035 40C31.0425 40 40.007 31.0425 40.007 19.9965C40.007 8.95044 31.0496 0 20.0035 0Z"
        fill="currentColor"
      />
      <path
        d="M51.7143 25.4636V28.1571C52.2004 27.5187 52.8185 27.2555 53.5167 27.2555C55.0259 27.2555 55.9469 28.4609 55.9469 30.03C55.9469 31.599 55.0154 32.815 53.5167 32.815C52.8185 32.815 52.2004 32.5518 51.7143 31.9134V32.6935H50.6005V25.4636H51.7143ZM53.264 31.813C54.2766 31.813 54.7627 31.0232 54.7627 30.0405C54.7627 29.0579 54.2766 28.2584 53.264 28.2584C52.2514 28.2584 51.7143 29.0684 51.7143 30.0405C51.7143 31.0126 52.2514 31.813 53.264 31.813Z"
        fill="currentColor"
      />
      <path
        d="M59.6231 32.9065C59.2075 34.0001 58.7417 34.5575 57.7291 34.5575C57.2633 34.5575 56.868 34.436 56.5237 34.193L56.9798 33.4031C57.1621 33.5352 57.3751 33.6162 57.6076 33.6162C58.0435 33.6162 58.2557 33.3124 58.4485 32.9171L58.5902 32.6239L56.3625 27.3787H57.649L59.1679 31.2168L60.5247 27.3787H61.7302L59.624 32.9074L59.6231 32.9065Z"
        fill="currentColor"
      />
      <path
        d="M68.5947 32.4205C68.2601 32.6741 67.8454 32.8159 67.3285 32.8159C66.6805 32.8159 66.1839 32.6028 65.8906 32.137C65.7084 31.864 65.6371 31.5391 65.6371 31.0231V28.2381H64.746V27.3769H65.6371V25.9487H66.7615V27.3769H68.4019V28.2381H66.7615V31.0328C66.7615 31.5699 67.0151 31.8226 67.4808 31.8226C67.7741 31.8226 67.9977 31.7108 68.1492 31.5893L68.5947 32.4196V32.4205Z"
        fill="currentColor"
      />
      <path
        d="M72.4725 28.5525C72.2999 28.4609 72.108 28.3905 71.8649 28.3905C71.1861 28.3905 70.5891 28.9778 70.5891 29.9904V32.6944H69.4752V27.3779H70.5891V28.239C70.9439 27.6817 71.4705 27.2766 72.0877 27.2766C72.3818 27.2766 72.6451 27.3576 72.7965 27.4589L72.4725 28.5525Z"
        fill="currentColor"
      />
      <path
        d="M77.8595 32.7754C77.3629 32.7754 76.9076 32.5421 76.7958 31.9856C76.36 32.6134 75.6512 32.8159 75.0233 32.8159C73.9297 32.8159 73.2007 32.218 73.2007 31.1755C73.2007 29.8591 74.3656 29.6566 75.2769 29.5554C76.0465 29.4744 76.6241 29.403 76.6241 28.8871C76.6241 28.3711 76.0368 28.2082 75.5402 28.2082C74.9934 28.2082 74.3753 28.3905 73.8796 28.7453L73.434 27.9044C74.0926 27.4791 74.7909 27.2563 75.6212 27.2563C76.8768 27.2563 77.7582 27.7626 77.7582 29.0385V31.459C77.7582 31.8033 77.87 31.9451 78.1333 31.9451C78.2346 31.9451 78.3561 31.9248 78.4265 31.8738L78.548 32.6433C78.3658 32.734 78.062 32.7754 77.8595 32.7754ZM76.6338 29.8688C76.33 30.2536 75.8748 30.2536 75.3069 30.3452C74.7389 30.4368 74.3242 30.5988 74.3242 31.1553C74.3242 31.7118 74.7495 31.9451 75.2963 31.9451C75.9954 31.9451 76.6329 31.57 76.6329 30.6789V29.8688H76.6338Z"
        fill="currentColor"
      />
      <path
        d="M79.4083 32.6944V27.3779H80.5222V28.158C80.958 27.5804 81.5453 27.2563 82.2638 27.2563C83.4288 27.2563 84.0971 28.0365 84.0971 29.2314V32.6944H82.9832V29.4841C82.9832 28.917 82.8009 28.2786 81.9301 28.2786C81.1403 28.2786 80.5222 28.836 80.5222 29.7165V32.6935H79.4083V32.6944Z"
        fill="currentColor"
      />
      <path
        d="M87.5196 32.8159C86.4365 32.8159 85.5551 32.4003 85.0488 31.6211L85.8386 31.0637C86.2437 31.6307 86.8917 31.9046 87.5803 31.9046C88.2689 31.9046 88.6845 31.6616 88.6845 31.1958C88.6845 30.73 88.1984 30.6085 87.3373 30.4667C86.2234 30.2739 85.3324 29.9604 85.3324 28.8668C85.3324 27.7732 86.315 27.2563 87.4183 27.2563C88.5216 27.2563 89.2718 27.7221 89.7067 28.1879L88.9979 28.836C88.6431 28.4309 88.0769 28.1677 87.4077 28.1677C86.9014 28.1677 86.4154 28.35 86.4154 28.7656C86.4154 29.2314 86.9824 29.3326 87.6816 29.4541C88.8157 29.6566 89.7878 29.9507 89.7878 31.0743C89.7878 32.1177 88.9372 32.8159 87.5196 32.8159Z"
        fill="currentColor"
      />
      <path
        d="M90.9315 32.6944V25.4645H92.0454V32.6944H90.9315Z"
        fill="currentColor"
      />
      <path
        d="M97.8075 32.7754C97.3108 32.7754 96.8556 32.5421 96.7447 31.9856C96.3088 32.6134 95.6 32.8159 94.9722 32.8159C93.8786 32.8159 93.1495 32.218 93.1495 31.1755C93.1495 29.8591 94.3145 29.6566 95.2258 29.5554C95.9954 29.4744 96.573 29.403 96.573 28.8871C96.573 28.3711 95.9857 28.2082 95.4891 28.2082C94.9423 28.2082 94.3241 28.3905 93.8284 28.7453L93.3829 27.9044C94.0415 27.4791 94.7397 27.2563 95.5701 27.2563C96.8257 27.2563 97.7071 27.7626 97.7071 29.0385V31.459C97.7071 31.8033 97.8189 31.9451 98.0822 31.9451C98.1834 31.9451 98.305 31.9248 98.3754 31.8738L98.4969 32.6433C98.3146 32.734 98.0109 32.7754 97.8083 32.7754H97.8075ZM96.5818 29.8688C96.278 30.2536 95.8228 30.2536 95.2548 30.3452C94.6869 30.4368 94.2722 30.5988 94.2722 31.1553C94.2722 31.7118 94.6975 31.9451 95.2443 31.9451C95.9434 31.9451 96.5809 31.57 96.5809 30.6789V29.8688H96.5818Z"
        fill="currentColor"
      />
      <path
        d="M102.587 32.4205C102.252 32.6741 101.838 32.8159 101.321 32.8159C100.673 32.8159 100.176 32.6028 99.8829 32.137C99.7007 31.864 99.6294 31.5391 99.6294 31.0231V28.2381H98.7383V27.3769H99.6294V25.9487H100.754V27.3769H102.394V28.2381H100.754V31.0328C100.754 31.5699 101.007 31.8226 101.473 31.8226C101.766 31.8226 101.99 31.7108 102.141 31.5893L102.587 32.4196V32.4205Z"
        fill="currentColor"
      />
      <path
        d="M108.379 30.3143H104.329C104.38 31.2864 105.017 31.8236 105.959 31.8236C106.587 31.8236 107.104 31.5805 107.499 31.1552L108.228 31.8544C107.67 32.4417 106.931 32.8168 105.919 32.8168C104.126 32.8168 103.134 31.6316 103.134 30.0625C103.134 28.4935 104.136 27.2572 105.807 27.2572C107.478 27.2572 108.399 28.4019 108.399 29.9305C108.399 30.0925 108.389 30.2245 108.379 30.3152V30.3143ZM107.275 29.5043C107.194 28.6132 106.576 28.1571 105.817 28.1571C104.976 28.1571 104.409 28.7145 104.348 29.5043H107.275Z"
        fill="currentColor"
      />
      <path
        d="M113.411 32.6944V31.9142C112.935 32.5526 112.307 32.8159 111.618 32.8159C110.109 32.8159 109.178 31.6105 109.178 30.0414C109.178 28.4723 110.109 27.2563 111.618 27.2563C112.318 27.2563 112.935 27.5196 113.411 28.158V25.4645H114.536V32.6944H113.411ZM111.861 28.2592C110.849 28.2592 110.363 29.0596 110.363 30.0414C110.363 31.0232 110.849 31.8139 111.861 31.8139C112.874 31.8139 113.411 31.024 113.411 30.0414C113.411 29.0587 112.874 28.2592 111.861 28.2592Z"
        fill="currentColor"
      />
      <path
        d="M21.3367 16.2702V29.285H18.0057V16.2702H11.7751V13.3698H21.3367V16.2702Z"
        fill="currentColor"
      />
      <path
        d="M26.389 17.0652C27.4238 17.0652 28.2627 16.2263 28.2627 15.1915C28.2627 14.1567 27.4238 13.3177 26.389 13.3177C25.3542 13.3177 24.5153 14.1567 24.5153 15.1915C24.5153 16.2263 25.3542 17.0652 26.389 17.0652Z"
        fill="currentColor"
      />
      <path
        d="M82.6055 21.4899C78.033 21.4899 75.137 18.2813 75.137 13.7942C75.137 9.30711 78.0339 6.06946 82.6055 6.06946C87.1771 6.06946 90.074 9.27805 90.074 13.7942C90.1022 18.3095 87.2062 21.4899 82.6055 21.4899ZM82.6055 8.76734C79.9358 8.76734 78.4028 10.9255 78.4028 13.7942C78.4028 16.6629 79.9076 18.792 82.6055 18.792C85.3034 18.792 86.78 16.6339 86.78 13.7942C86.78 10.9264 85.2752 8.76734 82.6055 8.76734Z"
        fill="currentColor"
      />
      <path
        d="M103.82 21.1781V19.0199C102.485 20.781 100.753 21.5189 98.85 21.5189C94.6473 21.5189 92.063 18.1676 92.063 13.8231C92.063 9.47869 94.6473 6.0984 98.85 6.0984C100.781 6.0984 102.514 6.83716 103.82 8.59731V1.12878H106.944V21.2063H103.82V21.1781ZM99.5033 8.85353C96.6919 8.85353 95.3288 11.0689 95.3288 13.795C95.3288 16.5211 96.6919 18.7083 99.5033 18.7083C102.315 18.7083 103.791 16.5219 103.791 13.795C103.82 11.0407 102.315 8.85353 99.5033 8.85353Z"
        fill="currentColor"
      />
      <path
        d="M135.029 9.67596C134.547 9.42061 134.007 9.22162 133.354 9.22162C131.479 9.22162 129.804 10.84 129.804 13.6515V21.1491H126.709V6.38196H129.804V8.76726C130.798 7.2334 132.246 6.09753 133.978 6.09753C134.801 6.09753 135.512 6.32471 135.937 6.60912L135.029 9.67596H135.029Z"
        fill="currentColor"
      />
      <path
        d="M138.125 21.1782V6.41106H141.22V8.5692C142.442 6.97899 144.06 6.07031 146.048 6.07031C149.286 6.07031 151.131 8.22845 151.131 11.5515V21.179H148.036V12.2621C148.036 10.7 147.525 8.91085 145.111 8.91085C142.925 8.91085 141.192 10.4447 141.192 12.9154V21.179H138.125V21.1782Z"
        fill="currentColor"
      />
      <path
        d="M171.348 21.1782V5.89949L165.782 21.1782H163.028L157.49 5.92856V21.1782H154.281V2.46375H159.109L164.447 17.0891L169.786 2.46375H174.528V21.1782H171.348Z"
        fill="#7101F9"
      />
      <path
        d="M186.751 5.33156V21.1782H183.457V5.33156H177.294V2.46375H192.914V5.33156H186.751Z"
        fill="#7101F9"
      />
      <path
        d="M124.116 14.5831H112.856C112.996 17.2846 114.769 18.7753 117.387 18.7753C119.013 18.7991 120.571 18.1219 121.662 16.9174L123.688 18.8607C122.14 20.494 120.084 21.5366 117.27 21.5366C112.286 21.5366 109.527 18.2417 109.527 13.8787C109.527 9.51578 112.315 6.07825 116.96 6.07825C121.604 6.07825 124.167 9.26132 124.167 13.5107C124.17 13.8682 124.153 14.2265 124.116 14.5823V14.5831ZM121.047 12.329C120.821 9.85213 119.106 8.58419 116.994 8.58419C114.657 8.58419 113.081 10.1348 112.91 12.329H121.047Z"
        fill="currentColor"
      />
      <path
        d="M50.6005 21.1782V6.41106H53.7043V8.51286C54.9 6.95083 56.4092 6.07031 58.3173 6.07031C60.4816 6.07031 61.9908 7.09258 62.6168 8.88179C63.927 7.06441 65.7207 6.07031 67.7714 6.07031C71.0178 6.07031 72.726 8.20028 72.726 11.4661V21.1782H69.6222V12.204C69.6222 10.5856 69.167 8.90997 66.8319 8.90997C64.7811 8.90997 63.1865 10.4148 63.1865 12.8573V21.1782H60.0827V12.204C60.0827 10.5856 59.6275 8.90997 57.2924 8.90997C55.2135 8.90997 53.647 10.4148 53.647 12.8573V21.1782H50.6005Z"
        fill="currentColor"
      />
    </svg>
  )
}
